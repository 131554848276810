@media only screen and (max-width: 600px){

    header{
      display: none;
    }
  
    .home{
      display: none;
    }

    .about{
        display: none;
    }
    .about_content{
        display: none;
    }

    .skills{
        display: none;
    }

    .skills_content{
        display: none;
    }

    .contact{
        display: none;
    }

    .home-mb{
        height: 100vh;
        position: relative;
        background-color: rgb(236, 236, 236);
        display: block;
    }

    .home-mb_logo{
        position: absolute;
        width: 50px;
        height: 50px;
        left: 20px;
        top: 20px;
        z-index: 2;
        background-color: black;
        border-radius: 50%;
    }

    .home-mb_logo_content{
        position: relative;
        width: 100%;
        height: 100%;
    }

    .home-mb_logo_content::after{
        content: '';
        position: absolute;
        width: 30px;
        height: 30px;
        background-color: rgb(235, 235, 235);
        right: -30px;
        bottom: 0;
        border-radius: 50%;
    }

    .home-mb_logo_content > span{
        position: absolute;
        color: white;
        font-weight: bold;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .home-mb_gradient{
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: radial-gradient(farthest-side at -10% 70%,blue,rgb(236, 236, 236));
        z-index: 1;
    }

    .home-mb_inner{
        position: absolute;
        z-index: 2;
        height: 100%;
        width: 100%;
    }

    .home-mb_main{
        position: relative;
        width: 100%;
        height: 100%;
    }

    .home-mb_img_container{
        width: 80vh;
        position: absolute;
        bottom: 10%;
        left: -40%;
    }

    .home-mb_img_container > img{
        width: 100%;
    }

    .home-mb_footer{
        position: absolute;
        background-color: black;
        width: 100%;
        height: 11%;
        bottom: 0;
    }

    .home-mb_title{
        position: absolute;
        top: 15%;
        right: 5%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
      
    .home-mb_firstmsg_bg, .home-mb_secondmsg_bg{
        width: fit-content;
        background-color: rgb(0, 0, 0);
        padding: .1vw 1vw;
        color: white;
        border-radius: 10px;
        position: relative;
        margin-bottom: 10px;
        word-break: break-all;
        font-size: 12px;
    }
      
    .home-mb_firstmsg_bg::after, .home-mb_secondmsg_bg::after{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-bottom: 15px solid black;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        bottom: 0;
        right: -15px;
    }

    .about-mb{
        position: relative;
        height: 140vh;
        display: block;
    }

    .about-mb_inner{
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 3;
    }

    .about-mb_transition{
        width: 100%;
        height: 20vh;
        background-color: black;
    }

    .about-mb_title{
        text-align: center;
        background-color: #C28B85;
        color: white;
        padding: 30px 0;
    }

    .about-mb_title > h1{
        margin: 0;
    }

    .about_content-mb{
        height: 100vh;
        display: block;
    }

    .about_content-mb_inner{
        position: fixed;
        width: 100%;
        top: 0;
        height: 100%;
        background-image: radial-gradient(farthest-side at 40% 60%,#a31809,rgb(236, 236, 236));

    }

    .about_content-mb_main{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .about_content-mb_photo{
        width: 150px;
        margin: 10px auto;
    }

    .about_content-mb_photo > img{
        width: 100%;
    }

    .about_content-mb_text{
        background-color: #C28B85;
        align-self: center;
        font-size: 2.3vh;
        color: white;
        padding: 0 15px;
        margin: 20px 10px;
        border-radius: 20px;
    }

    .skills-mb{
        position: relative;
        height: 100vh;
        display: block;
    }

    .skills_intro{
        z-index: 5;
    }

    .skills_intro_title{
        background-color: #95eba8;
        color: white;
        width: min-content;
        font-size: 3vh!important;
        height: 100%;
        padding: 2% 5%;
        font-size: 5rem;
        position: absolute;
        left: 35%;
    }

    .skills_content-mb{
        border-top: 30px solid transparent;
        height: max-content;
        background-color: #dbecde;
        display: block;
    }

    .skills_content-mb_inner{
        margin-top: 40vh;
    }

    .skills_content-mb_list{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .skills_item-mb{
        width: 200px;
        height: 200px;
        background-color: #e7e7e7;
        border-radius: 20px;
        position: relative;
        margin-top: 5%;
        margin-bottom: 5%;
        margin-left: 5%;
        margin-right: 5%;
        border: 10px solid #8be2348e;
    }

    .skills_item-mb > svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .skills_download_cv-mb{
        width: 300px;
        margin: 0 auto;
        margin-top: 20px;
        padding-bottom: 50px;
        height: 50px;
    }

    .skills_download_cv-mb > a > button{
        width: 100%;
        background-color: black;
        color: white;
        border: none;
        height: 100%;
        cursor: pointer;
    }
      
    .skills_download_cv-mb > a > button:active{
        outline: none;
    }

    .contact-mb{
        height: max-content;
        background-image: linear-gradient(to bottom, #dbecde, #e5e7d9, #e9e3da, #e6e0dd, #e0dfdf);
        position: relative;
        display: block;
    }

    .contact-mb_inner{
        position: relative;
        background: radial-gradient(
            farthest-side at bottom left,
            rgba(243, 143, 29, 0.5), 
            transparent
          ),
          radial-gradient(
            farthest-corner at bottom right,
            rgba(255, 163, 50, 0.5), 
            transparent 400px
          );
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .navbar-mb{
        position: fixed;
        width: 100%;
        height: 100vh;
        z-index: 6;
    }

    .navbar-mb_inner{
        position: relative;
        width: 100%;
        height: 100%;
    }

    .navbar-mb_inner_button > button{
        position: absolute;
        width: 50px;
        height: 50px;
        background-color: white;
        border: none;
        border-radius: 50%;
        top: 20px;
        left: 20px;
        z-index: 8;
    }

    .navbar-mb_inner_content{
        position: absolute;
        width: 50px;
        height: 50px;
        left: 20px;
        top: 20px;
        border-radius: 50%;
        background-color: white;
        z-index: 7;
    }

    .navbar-mb_list{
        padding: 0;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        font-size: 24px;
    }

    .navbar-mb_list > a{
        font-size: 5vh;
        margin: 6vh 0;
        font-weight: bold;
    }

    .navbar-mb_list > a:nth-child(1){
        color: #32AFFF;
    }

    .navbar-mb_list > a:nth-child(2){
        color: #FF30C5;
    }

    .navbar-mb_list > a:nth-child(3){
        color: #8AE234;
    }

    .navbar-mb_list > a:nth-child(4){
        color: #FFB800;
    }

    .contact_form{
        margin-top: 0;
    }

    .contact_form > h3{
        margin-top: 0;
    }

  
}

@media screen and (min-width: 600px) and (max-width: 1024px){
    .home_img_container::after{
        display: none;
    }

    .home_main{
        width: 100vw;
        height: 100vh;
    }

    .home_img_container{
        width: 100vh;
        position: absolute;
        bottom: -2%;
        left: 40%;
        transform: translateX(-50%);
    }

    .home_inner{
        bottom: 0;
        top: initial;
        left: initial;
        transform: none;
        left: 0;
    }

    .home_title{
        top: 20%;
        right: 10%;
    }

    .about_content_card{
        position: absolute;
        width: 800px;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .about__img{
        top: -350px;
        left: 50%;
        transform: translateX(-50%);
    }

    .about__text{
        top: -100px;
        left: 50%;
        transform: translateX(-50%)!important;
    }

    .skills_intro_title{
        font-size: 3.5vh!important;
    }

    .contact_inner_bg{
       display: flex;
       align-items: center;
       justify-content: space-around;
       flex-direction: column;
    }

}