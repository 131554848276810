
.box{
  width: 200px;
  height: 200px;
  background-color: red;
  margin: 200px auto;
}

.scaling{
  height: 100vh;
}

.blue_box{
  width: 200px;
  height: 200px;
  background-color: blue;
  margin: 200px auto;
}

.home-mb{
  display: none;
}

.about-mb{
  display: none;
}

.about_content-mb{
  display: none;
}

.skills-mb{
  display: none;
}

.skills_content-mb{
  display: none;
}

.contact-mb{
  display: none;
}

.App{
  background-color: rgb(224, 223, 223);
  position: relative;
  overflow: hidden;
}

header{
  position: fixed;
  z-index: 5;
  width: 400px;
  background-color: white;
  left: 50%;
  top: 1%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-evenly;
  box-shadow: 0px 2px 4px rgba(172, 172, 172, 0.664);
  align-items: center;
  height: 50px;
  border-radius: 50px;
}

.header_navigation > span{
  margin: 0 10px;
}

header > span {
  font-weight: bold;
}

header > span, .header_navigation > span{
  cursor: pointer;
}

.home{
  height: 100vh;
  position: relative;
  background-color: rgb(236, 236, 236);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.home_gradient{
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(farthest-side at 30% 50%,blue,rgb(236, 236, 236));
  z-index: 1;
}

.home_inner{
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
}

.home_img_container{
  width: 60vw;
  position: relative;
}

.home_img_container::after{
  content: '';
  width: 70%;
  height: 10vh;
  background-color: black;
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: -1
}

.home_main{
  position: relative;
  width: 60vw;
}

.home_img_container > img{
  width: 100%;
}

.home_title{
  position: absolute;
  top: 0;
  right: -20%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.home_firstmsg_bg, .home_secondmsg_bg{
  width: fit-content;
  background-color: rgb(0, 0, 0);
  padding: .1vw 2vw;
  color: white;
  border-radius: 10px;
  position: relative;
  margin-bottom: 10px;
  word-break: break-all;
}

.home_firstmsg_bg::after, .home_secondmsg_bg::after{
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 15px solid black;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  bottom: 0;
  right: -15px;
}

.about{
  width: 100%;
  height: 100vh;
}

.about_transition{
  width: 100%;
  height: 200vh;
  display: block;
}

.about_intro{  
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.about_intro_inner{
  width: 100%;
  height: 100%;
  background-color: #C28B85;
  display: flex;
  justify-content: space-between;
}

.about_intro_inner > h1{
  font-size: 12rem;
  color: white;
}

.about_intro_img{
  display: flex;
  justify-content: center;

}

.sky_img{
  height: 100%;
}

.about_content{
  width: 100%;
  height: 230vh;
  background-image: radial-gradient(farthest-side at 30% 50%,#C28B85,rgb(236, 236, 236));
}

.about_content_inner{
  width: 100%;
  height: 100vh;
  top: 0;
}

.about_content_main{
  width: 100%;
  height: 100%;
}

.about_content_card{
  position: absolute;
  width: 1024px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about_content_card_inner{
  position: relative;
}

.about__img{
  position: absolute;
  top: -240px;
  right: 20px;
  z-index: 3;
}

.about__text{
  position: absolute;
  z-index: 2;
  top: -200px;
  left: 50px;
  width: 600px;
  height: 600px;
  background-color: #C28B85;
  padding: 20px 20px;
  border-radius: 50%;
  display: flex;
}

.about__text__inner{
  align-self: center;
  padding: 20px;
  color: white;
}

.about__circle{
  position: absolute;
  z-index: 1;
  top: -200px;
  left: 20px;
  width: 620px;
  height: 620px;
  border-radius: 50%;
  transform: rotate(360deg);
}


.about__circle__inner{
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: black;
}

.about_rocket{
  position: absolute;
  width: 30vw;
  left: 0;
  bottom: 0;
  height: auto;
  z-index: -1;
}

.about_rocket > img{
  width: 100%;
}

.about_planet{
  position: absolute;
  right: 6%;
  bottom: 10%;

}

.about_planet > svg{
  height: 28vh;
  width: 28vw;
  opacity: .3;
}

.about_outro{
  position: absolute;
  width: 100%;
  height: 200vh;
}

.about_outro > img{
  position: relative;
  top: 3vh;
  width: 100%;
}

.about_outro_footer{
  width: 100%;
  height: 100%;
  background-color: #88BE94;
}


.skills{
  width: 100%;
  height: 50vh;
}

.skills_intro{
  width: 100%;
  height: 100vh;
  top: 0;
  background-color: #88BE94;
  position: relative;
}


.skills_intro_title{
  background-color: #95eba8;
  color: white;
  width: min-content;
  height: 100%;
  padding: 2% 5%;
  font-size: 5rem;
  position: absolute;
  left: 35%;
}

.skills_stat_one{
  background-color: rgba(226, 209, 242, 0.469);
  height: 100%;
  width: 20%;
  padding: 2% 5%;
  top: 30%;
  left: 25%;
  transform: translateX(-50%);
  position: absolute;
}

.skills_stat_two{
  background-color: rgba(244, 203, 142, 0.469);
  height: 100%;
  width: 20%;
  padding: 2% 5%;
  top: 35%;
  left: 50%;
  position: absolute;
}


.skills_content{
  width: 100%;
  height: 100vh;
  position: relative;
}

.skills_content_inner{
  width: 100%;
  height: 100vh;
  top: 0;
  position: relative;
  background-color: #dbecde;
}

.skills_transition{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #88BE94;
}

.skills_content_footer{
  position: absolute;
  background-image: url('./imgs/skills__wave.png');
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  height: 200px;
  bottom: 0;
  z-index: -1;
}

.skills_content_icon{
  position: absolute;
  bottom: 5%;
  right: 5%;
  width: 200px;
  height: 200px;
}

.skills_content_icon > svg{
  width: 100%;
  height: 100%;
}

.skills_list{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 60vw;
  height: max-content;
  padding-bottom: 10%;
  background-color: #c7e6cf;
  border-radius: 10%;
  box-shadow: 12px 18px 15px -3px rgba(0,0,0,0.1);
}

.skills_list > h1{
  text-align: center;
}

.skills_list_content{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.skills_item{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #F3F3F3;
  position: relative;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 5%;
  margin-right: 5%;
}

.skills_item::after{
  content: '';
  position: absolute;
  background-color: #8be2348e;
  width: 120%;
  height: 120%;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  border-radius: 50%;
}

.skills_item > svg{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.skills_download_cv{
  width: 300px;
  margin: 0 auto;
  margin-top: 20px;
  height: 50px;
}

.skills_download_cv > a > button{
  width: 100%;
  background-color: black;
  color: white;
  border: none;
  height: 100%;
  cursor: pointer;
}

.skills_download_cv > a > button:active{
  outline: none;
}

.contact{
  width: 100%;
  height: 100vh;
  
  position: relative
}

.contact_inner{
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(247, 245, 245);
}

.contact_inner_content{
  position: relative;
  width: 100%;
  height: 100%;
}

.contact_inner_bg{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-image: radial-gradient(farthest-side at 40% 50%,#FFB800,rgb(236, 236, 236));
}

.contact_form{
  margin-top: 70px;
}

.contact_form > h3{
  text-align: center;
  font-size: 2.5rem;
}

.contact_form_inner{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

form{
  width: max-content;
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative;
  border-radius: 20px;
  width: 320px;
  padding: 50px 40px 50px 40px;
}

.form_header{
  width: 100%;
  height: 30px;
  position: absolute;
  top: 0;
  background-color: rgb(46, 46, 46);
  left: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.form_header_circle{
  width: 10px;
  height: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgb(253, 49, 49);
  border-radius: 50%;
}

.form_header_circle:nth-child(2){
  margin-left: 15px;
  background-color: rgb(253, 171, 49);
}

.form_header_circle:nth-child(3){
  margin-left: 30px;
  background-color: rgb(57, 173, 34);
}

.contact_email > input{
  border: none;
  border-bottom: 2px solid rgb(137, 137, 137);
  width: 100%;
}

.contact_email > input:focus{
  outline: none;
}

.contact_msg > textarea{
  resize: none;
  width: 96%;
  height: 150px;
  border: none;
  padding: 3%;
  border-radius: 20px;
  box-shadow: 6px 14px 16px 11px rgba(0,0,0,0.1);
}

.contact_msg > textarea:focus{
  outline: none
}

form > button{
  width: 100px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  margin-top: 20px;
  align-self:flex-end;
  cursor: pointer;
}

.contact_other > h3{
  background-color: black;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  width: max-content;
  font-size: 1.8rem;
}

.contact_other > a > svg{
  margin: 0 20px;
}

.contact_other > a{
  cursor: pointer;
}

.contact_other > p{
  background-color: black;
  color: white;
  width: max-content;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: bold;
  margin: 50px auto;
}

.submitted{
  position: absolute;
  width: max-content;
  height: max-content;
  background-color: black;
  border-radius: 20px;
  color: white;
  top: 100px;
  left: 50%;
  padding: 0 20px;
  transform: translateX(-50%);
  z-index: 1;
}












